import { useEffect, useRef, useState } from 'react';
import Script from 'next/script';

export interface GoogleCredentialResponse {
  clientId: string;
  credential: string;
  // eslint-disable-next-line camelcase
  select_by: string;
}

interface Props {
  /**
   * This is fired when we receive a response from Google.
   */
  handleGoogleCredentialResponse: (response: GoogleCredentialResponse) => void;
  /**
   * API reference: https://developers.google.com/identity/gsi/web/reference/html-reference#data-theme
   */
  theme?: 'outline' | 'filled_blue';
}

const GoogleSigninButton = ({
  handleGoogleCredentialResponse,
  theme = 'outline',
}: Props): JSX.Element => {
  const divRef = useRef<HTMLDivElement>(null);

  // If the Google library has already been loaded, then set isGoogleScriptLoaded to be true on first render.
  // This is because the NextJS <Script> tag will load the Google Script the first time this component is rendered,
  // But the second time the script won't be loaded again.
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(
    typeof window !== 'undefined' ? !!window.google : false,
  );

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (divRef.current && isGoogleScriptLoaded && !isInitialized) {
      window.google.accounts.id.initialize({
        // eslint-disable-next-line camelcase
        client_id: process.env.googleWebClientId as string,
        callback: handleGoogleCredentialResponse,
      });

      // API reference: https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton
      window.google.accounts.id.renderButton(divRef.current, {
        theme,
        size: 'large',
        type: 'standard',
        text: 'continue_with',
        shape: 'rectangular',
        // eslint-disable-next-line camelcase
        logo_alignment: 'left',
        width: '250',
      });

      setIsInitialized(true);
    }
  }, [
    isGoogleScriptLoaded,
    divRef,
    handleGoogleCredentialResponse,
    isInitialized,
    theme,
  ]);

  return (
    <>
      <Script
        id="load-google-signin"
        src="https://accounts.google.com/gsi/client"
        strategy="lazyOnload"
        onLoad={() => setIsGoogleScriptLoaded(true)}
      />
      <div ref={divRef} />
    </>
  );
};

export default GoogleSigninButton;
